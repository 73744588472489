import {debounce} from 'lodash'
import {KTIcon} from '../../_metronic/helpers'

const CommonSearch = ({title, onChange}) => {
  const handleDebouncedChange = debounce((event) => {
    onChange && onChange(event.target.value)
  }, 500)

  return (
    <form className='align-items-center bg-white d-flex position-relative'>
      <KTIcon
        iconName='magnifier'
        className=' fs-2 text-gray-700 position-absolute top-50 translate-middle-y ms-4'
      />

      <input
        type='text'
        className='form-control bg-transparent ps-13 fs-7  h-40px'
        name='search'
        placeholder={`Quick Search ${title}`}
        onChange={handleDebouncedChange}
      />

      <span className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5'>
        <span className='spinner-border h-15px w-15px align-middle text-gray-400' />
      </span>

      <span
        className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-4'
        data-kt-search-element='clear'
      >
        <KTIcon iconName='cross' className='fs-2 text-lg-1 me-0' />
      </span>
    </form>
  )
}

export default CommonSearch
