import {
  GET_PERMISSIONS,
  GET_PERMISSIONS_FAIL,
  GET_PERMISSIONS_SUCCESS,
  GET_ROLES,
  GET_ROLES_FAIL,
  GET_ROLES_SUCCESS,
  GET_STAFF,
  GET_STAFF_FAIL,
  GET_STAFF_SUCCESS,
  MANAGE_PERMISSIONS,
  MANAGE_PERMISSIONS_FAIL,
  MANAGE_PERMISSIONS_SUCCESS,
  MANAGE_ROLES,
  MANAGE_ROLES_FAIL,
  MANAGE_ROLES_SUCCESS,
  MANAGE_STAFF,
  MANAGE_STAFF_FAIL,
  MANAGE_STAFF_SUCCESS,
} from '../actions/index'

const intialState = {
  loading: false,
  fetchLoading: false,
  rolesData: [],
  staffList: {},
  permissionsData: [],
  allPermissions: [],
}

const rolesReducer = (state = intialState, action) => {
  switch (action.type) {
    // fetch roles
    case GET_ROLES:
      return {...state, fetchLoading: true}
    case GET_ROLES_SUCCESS:
      return {...state, fetchLoading: false, rolesData: action?.payload}
    case GET_ROLES_FAIL:
      return {...state, fetchLoading: false}

    // fetch staff
    case GET_STAFF:
      return {...state, fetchLoading: true}
    case GET_STAFF_SUCCESS:
      return {...state, fetchLoading: false, staffList: action?.payload}
    case GET_STAFF_FAIL:
      return {...state, fetchLoading: false}

    // fetch permissions
    case GET_PERMISSIONS:
      return {...state, fetchLoading: true}
    case GET_PERMISSIONS_SUCCESS:
      const {isSidebar} = action
      return {
        ...state,
        fetchLoading: false,
        allPermissions: isSidebar
          ? action?.payload?.permissions?.permissions
          : state.allPermissions,
        permissionsData: isSidebar ? state.permissionsData : action?.payload,
      }
    case GET_PERMISSIONS_FAIL:
      return {...state, fetchLoading: false}

    // manage roles
    case MANAGE_ROLES:
      return {...state, loading: true}
    case MANAGE_ROLES_SUCCESS:
      return {...state, loading: false}
    case MANAGE_ROLES_FAIL:
      return {...state, loading: false}

    // manage permissions
    case MANAGE_PERMISSIONS:
      return {...state, loading: true}
    case MANAGE_PERMISSIONS_SUCCESS:
      return {...state, loading: false}
    case MANAGE_PERMISSIONS_FAIL:
      return {...state, loading: false}

    // manage staff
    case MANAGE_STAFF:
      return {...state, loading: true}
    case MANAGE_STAFF_SUCCESS:
      return {...state, loading: false}
    case MANAGE_STAFF_FAIL:
      return {...state, loading: false}

    default:
      return state
  }
}

export default rolesReducer
