import {KTIcon} from '../../_metronic/helpers'

const BackButton = ({event, className}) => {
  return (
    <div className={`d-flex justify-content-end ${className} `}>
      <span
        style={{cursor: 'pointer'}}
        onClick={event}
        className='d-flex align-items-center btn-sm btn btn-dark'
      >
        <KTIcon iconName='arrow-left' className='fs-2' />
        Back
      </span>
    </div>
  )
}

export default BackButton
