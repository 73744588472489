import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {toAbsoluteUrl} from '../../../helpers'
import {AsideMenu} from './AsideMenu'
import {AsideUserMenu} from '../../../partials'
import ForLightMode from '../../../../app/component/FetchMode'

const AsideDefault = () => {
  const {classes} = useLayout()
  const isForLight = ForLightMode()
  return (
    <div
      id='kt_aside'
      className={clsx('aside py-10', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_toggle'
    >
      {/* begin::Brand */}
      <div className='flex-column-auto px-9 mb-9' >
        {/* begin::Logo */}
        <Link to='/dashboard'>
          <img
            alt='Logo'
            className={`w-200px logo ${isForLight ? 'theme-light-show' : 'theme-dark-show'}`}
            src={toAbsoluteUrl(
              isForLight ? '/media/logos/newLogo.svg' : '/media/logos/BattleTradingDark.svg'
            )}
          />
        </Link>
        {/* end::Logo */}
      </div>
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div id='kt_aside_menu' className='aside-menu flex-column-fluid ps-5 pe-3 mb-10'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}

      {/* begin::Footer */}
      <div className='aside-footer flex-column-auto mb-10 px-9' id='kt_aside_footer'>
        <AsideUserMenu />
      </div>
      {/* end::Footer */}
    </div>
  )
}

export {AsideDefault}
