import {combineReducers} from 'redux'

import rolesReducer from './roleReducer'
import otherReducer from './otherReducers'
import eventsReducer from './eventReducer'
import bannerReducer from './bannerReducer'
import categoriesReducer from './categoriesReducer'
import subCategoriesReducer from './subCategoriesReducer'
import userReducer from './userReducer'
import transactionReducer from './TransactionsReducer'
import eventSourcersReducer from './eventSourcesReducer'
import cricketSourcersReducer from './cricketReducer'
import cricketToursReducer from './cricketToursReducer'

const rootReducer = combineReducers({
  eventsReducer,
  eventSourcersReducer,
  cricketSourcersReducer,
  cricketToursReducer,
  categoriesReducer,
  subCategoriesReducer,
  bannerReducer,
  otherReducer,
  rolesReducer,
  userReducer,
  transactionReducer,
})

export default rootReducer
