import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import toast from 'react-hot-toast'
import ForLightMode from '../../../component/FetchMode'

const loginSchema = Yup.object().shape({
  email: Yup.string().min(10, 'Minimum 10 digit').required('email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const isForLight = ForLightMode()
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [showPassword, setShowPassword] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password)
        toast.success(auth.message)
        saveAuth(auth.data)
        setCurrentUser(auth.data)
        window.location.reload()
      } catch (error) {
        toast.error(error.response.data.error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form py-10 w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder'>Sign In</h1>
        <p style={{color: '#A1A5B7', fontSize: '15px'}}>Hey , Welcome back to your admin panel</p>
      </div>

      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className='fv-row'>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent my-5',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row '>
        <p
          className='mx-4 mb-1'
          onClick={() => setShowPassword(!showPassword)}
          style={{textAlign: 'right'}}
        >
          {showPassword ? (
            <>
              <i className='bi bi-eye-fill'></i>
            </>
          ) : (
            <>
              <i className='bi bi-eye-slash-fill'></i>
            </>
          )}
        </p>
        <input
          type={showPassword ? 'text' : 'password'}
          autoComplete='off'
          placeholder='password'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className=' d-flex my-5 align-items-center gap-3 fs-base fw-semibold'>
        <input type='checkbox' id='rememberPassword' />
        <label htmlFor='rememberPassword'>Remember Password</label>
      </div>

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button type='submit' className={`btn btn-dark ${isForLight ? 'btn-dark' : 'btn-light'}`}>
          {!loading && <span className={``}>Login</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
