import {
  bet_logs_list,
  fetch_setting,
  get_dashboard,
  send_notification,
  update_password,
  update_setting,
  user_detail,
  user_lists,
} from '../../utils/api'
import {
  FETCH_BET_LOGS,
  FETCH_BET_LOGS_FAIL,
  FETCH_BET_LOGS_SUCCESS,
  FETCH_DASHBOARD,
  FETCH_DASHBOARD_FAIL,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_EVENT_BET_LOG,
  FETCH_EVENT_BET_LOG_FAIL,
  FETCH_EVENT_BET_LOG_SUCCESS,
  FETCH_USERS,
  FETCH_USERS_FAIL,
  FETCH_USERS_SUCCESS,
  GET_SETTINGS,
  GET_SETTINGS_FAIL,
  GET_SETTINGS_SUCCESS,
  NOTIFICATIONS,
  NOTIFICATIONS_FAIL,
  NOTIFICATIONS_SUCCESS,
  REMOVE_USERS,
  REMOVE_USERS_FAIL,
  REMOVE_USERS_SUCCESS,
  UPDATE_SETTINGS,
  UPDATE_SETTINGS_FAIL,
  UPDATE_SETTINGS_SUCCESS,
} from '.'
import {callApi} from '../../../_metronic/helpers/api'

// get dashboard
export const getDashboard = () => async (dispatch) => {
  dispatch({type: FETCH_DASHBOARD})
  try {
    const response = await callApi({url: get_dashboard, method: 'GET'})
    dispatch({type: FETCH_DASHBOARD_SUCCESS, payload: response})
  } catch (error) {
    dispatch({type: FETCH_DASHBOARD_FAIL, payload: error})
  }
}

// fetch users
export const getUsers = (params) => async (dispatch) => {
  dispatch({type: FETCH_USERS})
  try {
    const response = await callApi({url: user_lists, params, method: 'GET'})
    dispatch({type: FETCH_USERS_SUCCESS, payload: response.data})
  } catch (error) {
    dispatch({type: FETCH_USERS_FAIL, payload: error})
  }
}

// fetch setting
export const getSettings = () => async (dispatch) => {
  dispatch({type: GET_SETTINGS})
  try {
    const response = await callApi({url: fetch_setting, method: 'GET'})
    dispatch({type: GET_SETTINGS_SUCCESS, payload: response.data.setting})
  } catch (error) {
    dispatch({type: GET_SETTINGS_FAIL, payload: error})
  }
}

// update setting
export const updateSettings = (payload, callback) => async (dispatch) => {
  dispatch({type: UPDATE_SETTINGS})
  try {
    await callApi({url: update_setting, payload, method: 'PUT'})
    dispatch({type: UPDATE_SETTINGS_SUCCESS})
    callback()
    return dispatch(getSettings())
  } catch (error) {
    dispatch({type: UPDATE_SETTINGS_FAIL, payload: error})
  }
}

// update setting
export const updatePassword =
  ({data, callback}) =>
  async (dispatch) => {
    dispatch({type: UPDATE_SETTINGS})
    try {
      await callApi({url: update_password, payload: data, method: 'POST'})
      dispatch({type: UPDATE_SETTINGS_SUCCESS})
      callback()
    } catch (error) {
      dispatch({type: UPDATE_SETTINGS_FAIL, payload: error})
    }
  }

// fetch bet logs
export const getBetLogs = (params) => async (dispatch) => {
  dispatch({type: FETCH_BET_LOGS})
  try {
    const response = await callApi({url: bet_logs_list, params, method: 'GET'})
    dispatch({type: FETCH_BET_LOGS_SUCCESS, payload: response.data})
  } catch (error) {
    dispatch({type: FETCH_BET_LOGS_FAIL, payload: error})
  }
}

// fetch bet logs
export const getBetLogsById =
  ({id, params , idType}) =>
  async (dispatch) => {
    dispatch({type: FETCH_EVENT_BET_LOG})
    try {
      const response = await callApi({url: `${bet_logs_list}?${idType}=${id}`, params, method: 'GET'})
      dispatch({type: FETCH_EVENT_BET_LOG_SUCCESS, payload: response.data})
    } catch (error) {
      dispatch({type: FETCH_EVENT_BET_LOG_FAIL, payload: error})
    }
  }

// remove user
export const removeUsers =
  ({params, userId, callback}) =>
  async (dispatch) => {
    dispatch({type: REMOVE_USERS})
    try {
      const response = await callApi({url: `${user_lists}/${userId}`, method: 'DELETE'})
      dispatch({type: REMOVE_USERS_SUCCESS, payload: response.data})
      callback()
      dispatch(getUsers(params))
    } catch (error) {
      dispatch({type: REMOVE_USERS_FAIL, payload: error})
    }
  }

// get paths to check privacy
export const setPathsToCheck = (paths) => (dispatch) => {
  dispatch({type: 'SET_PATHS_TOCHECK', payload: paths})
}


// create new event
export const sendNotification =
  ({payload, setLoading, callback}) =>
  async (dispatch) => {
    setLoading(true)
    dispatch({type: NOTIFICATIONS})
    try {
      const response = await callApi({url: send_notification, payload, method: 'POST'})
      dispatch({type: NOTIFICATIONS_SUCCESS, payload: response.data})
      callback()
  
      setLoading(false)
    } catch (error) {
      setLoading(false)
      dispatch({type: NOTIFICATIONS_FAIL, payload: error})
    }
  }
