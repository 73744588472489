export const API_URL = process.env.REACT_APP_API_URL

// Auth
export const user_lists = '/admin/users'
export const fetch_setting = '/admin/setting'
export const update_setting = '/admin/updated-setting'
export const update_password = '/auth/admin/change-password'

// Dashboard
export const get_dashboard = '/admin/statistics'

// Events
export const CREATE_EVENTS = '/admin/events'
export const GET_ALL_EVENTS = '/admin/events'
export const GET_EVENT_BY_ID = '/admin/events'
export const UPDATE_EVENTS = '/admin/events'
export const DELETE_EVENTS = '/admin/events'
export const CANCEL_EVENTS = '/admin/event/cancel'
export const get_event_questions = '/admin/event-questions'
export const create_auto_order = '/admin/trades/autoOrder'
export const event_rules_generate = '/admin/generate-rules'
export const declare_result='/admin/event/declare-result'

// event sources
export const get_all_event_sources = '/admin/event-sources'
export const create_event_sources = '/admin/event-sources'
export const update_event_sources = '/admin/event-sources'
export const delete_event_sources = '/admin/event-sources'
export const get_event_sources = '/admin/event-sources'


// event sources
export const get_all_cricket_sources = '/admin/cricket-matches'
export const create_cricket_sources = '/admin/cricket-matches'
export const update_cricket_sources = '/admin/cricket-matches'
export const delete_cricket_sources = '/admin/cricket-matches'
export const get_cricket_sources = '/admin/cricket-matches'


// cricket tours 

export const get_all_series_tours = 'https://liveapi.tteks.com/api/series/tours'
export const get_cricket_tours = '/admin/cricket-tours'
export const update_cricket_tours = '/admin/cricket-tours'


// upcoming 
export const get_upcoming_matches = 'https://liveapi.tteks.com/api/series/tours'
export const filter_upcoming_matches = 'https://liveapi.tteks.com/api/series?tournament_id='

// notification
export const send_notification = '/admin/send-asset-notifications'
 
// Categories
export const CREATE_CATEGORIES = '/admin/categories'
export const GET_ALL_CATEGORIES = '/admin/categories'
export const DELETE_CATEGORIES = '/admin/categories'
export const GET_CATEGORIES_BY_ID = '/admin/categories'
export const UPDATE_CATEGORIES_BY_ID = '/admin/categories'

// Sub-Categories
export const CREATE_SUB_CATEGORIES = '/admin/subcategories'

// Banners
export const CREATE_BANNER = '/admin/banners'
export const GET_ALL_BANNERS = '/admin/banners'
export const GET_BANNER_BY_ID = '/admin/banners'
export const UPDATE_BANNER = '/admin/banners'
export const DELETE_BANNER = '/admin/banners'

// Bet logs
export const bet_logs_list = '/admin/orders'

// Roles & Permmissons
export const role_list = '/admin/role-list'
export const role_view = '/admin/role-view'
export const role_store = '/admin/role-store'
export const role_update = '/admin/role-update'
export const role_status = '/admin/role-status'
export const role_delete = '/admin/role-delete'

export const permission_view = '/admin/permission-view'
export const permission_store = '/admin/permission-store'

// Get staffs
export const get_staff = '/admin/staffs'
export const manage_staff = '/admin/staff'
export const manage_staff_status = '/admin/staff-status'

//  User Detail
export const user_detail = '/admin/user'
export const delete_user_detail = '/admin/users'

// Transactions
export const transactions_detail = '/admin/transactions'
