import {
  FETCH_EVENTS,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_FAIL,
  MANAGE_EVENTS,
  MANAGE_EVENTS_SUCCESS,
  MANAGE_EVENTS_FAIL,
  GENERATE_QUESTIONS,
  GENERATE_QUESTIONS_SUCCESS,
  GENERATE_QUESTIONS_FAIL,
  EVENT_INPUT,
  EVENT_INPUT_FAIL,
  EVENT_INPUT_SUCCESS,
  CLEAR_EVENT_INPUT,
  EVENT_ORDER,
  EVENT_ORDER_SUCCESS,
  EVENT_ORDER_FAIL,
  GENERATE_RULES,
  GENERATE_RULES_SUCCESS,
  GENERATE_RULES_FAIL,
  DECLARE_RESULT,
  DECLARE_RESULT_SUCCESS,
  DECLARE_RESULT_FAIL,
} from '../actions/index'

const intialState = {
  loading: false,
  fetchLoading: false,
  questionLoading: false,
  resultLoading: false,
  rulesLoading: false,
  eventInputData: {},
  eventsData: {},
  eventsList: [],
  eventQuestions: [],
  rulesData:[],
  eventAutoData: [],
  resultData:[],
}
const eventsReducer = (state = intialState, action) => {
  switch (action.type) {
    // fetch events
    case FETCH_EVENTS:
      return {...state, fetchLoading: true}
    case FETCH_EVENTS_SUCCESS:
      const key = Object.keys(action?.payload)[0]
      return {...state, fetchLoading: false, [key]: action?.payload[key]}
    case FETCH_EVENTS_FAIL:
      return {...state, fetchLoading: false}

    // fetch events QUESTIONS
    case GENERATE_QUESTIONS:
      return {...state, questionLoading: true, eventQuestions: []}
    case GENERATE_QUESTIONS_SUCCESS:
      return {...state, questionLoading: false, rulesData: action?.payload}
    case GENERATE_QUESTIONS_FAIL:
      return {...state, questionLoading: false}

    // event sources

    // manage events
    case MANAGE_EVENTS:
      return {...state, loading: true}
    case MANAGE_EVENTS_SUCCESS:
      return {...state, loading: false}
    case MANAGE_EVENTS_FAIL:
      return {...state, loading: false}

    case EVENT_INPUT:
      return {...state, loading: true}
    case EVENT_INPUT_SUCCESS:
      return {
        ...state,
        loading: false,
        eventInputData: {...state.eventInputData, ...action?.payload},
      }
    case EVENT_INPUT_FAIL:
      return {...state, loading: false}

    // event auto order
    case EVENT_ORDER:
      return {...state, questionLoading: true, eventAutoData: []}
    case EVENT_ORDER_SUCCESS:
      return {...state, questionLoading: false, eventAutoData: action?.payload}
    case EVENT_ORDER_FAIL:
      return {...state, questionLoading: false}

       // generate rules through ai
    case GENERATE_RULES:
      return {...state, rulesLoading: true, rulesData: []}
    case GENERATE_RULES_SUCCESS:
      return {...state, rulesLoading: false, rulesData: action?.payload}
    case GENERATE_RULES_FAIL:
      return {...state, rulesLoading: false}

      case DECLARE_RESULT:
        return {...state, resultLoading: true, resultData: []}
      case DECLARE_RESULT_SUCCESS:
        return {...state, resultLoading: false, resultData: action?.payload}
      case DECLARE_RESULT_FAIL:
        return {...state, resultLoading: false}
  
    //
    case CLEAR_EVENT_INPUT:
      return {...state, eventInputData: {}}

    default:
      return state
  }
}

export default eventsReducer
