import {
  get_staff,
  manage_staff,
  manage_staff_status,
  permission_store,
  permission_view,
  role_delete,
  role_list,
  role_status,
  role_store,
  role_update,
} from '../../utils/api'
import {callApi} from '../../../_metronic/helpers/api'
import {
  GET_PERMISSIONS,
  GET_PERMISSIONS_FAIL,
  GET_PERMISSIONS_SUCCESS,
  GET_ROLES,
  GET_ROLES_FAIL,
  GET_ROLES_SUCCESS,
  GET_STAFF,
  GET_STAFF_FAIL,
  GET_STAFF_SUCCESS,
  MANAGE_PERMISSIONS,
  MANAGE_PERMISSIONS_FAIL,
  MANAGE_PERMISSIONS_SUCCESS,
  MANAGE_ROLES,
  MANAGE_ROLES_FAIL,
  MANAGE_ROLES_SUCCESS,
  MANAGE_STAFF,
  MANAGE_STAFF_FAIL,
  MANAGE_STAFF_SUCCESS,
} from '.'

// fetch roles
export const getRoles = () => async (dispatch) => {
  dispatch({type: GET_ROLES})
  try {
    const response = await callApi({url: role_list, method: 'GET'})
    dispatch({type: GET_ROLES_SUCCESS, payload: response.data})
  } catch (error) {
    dispatch({type: GET_ROLES_FAIL, payload: error}) // Dispatching an action if there's an error
  }
}

// fetch permissions
export const getPermissions = (roleId, isSidebar) => async (dispatch) => {
  dispatch({type: GET_PERMISSIONS})
  try {
    const response = await callApi({url: `${permission_view}/${roleId}`, method: 'GET'})
    dispatch({type: GET_PERMISSIONS_SUCCESS, isSidebar, payload: response?.data})
  } catch (error) {
    dispatch({type: GET_PERMISSIONS_FAIL, payload: error}) // Dispatching an action if there's an error
  }
}

// manage role
export const manageRoles =
  ({payload, roleId, method, purpose, callback}) =>
  async (dispatch) => {
    dispatch({type: MANAGE_ROLES})
    try {
      const getUrls = () => {
        switch (purpose) {
          case 'create':
            return role_store

          case 'update':
            return role_update + '/' + roleId

          case 'delete':
            return role_delete + '/' + roleId

          case 'status':
            return role_status + '/' + roleId
        }
      }
      const response = await callApi({url: getUrls(), payload, method})
      dispatch(getRoles())

      dispatch({type: MANAGE_ROLES_SUCCESS, payload: response.data})
      callback(response)
    } catch (error) {
      const errorMessage = error.response.data.error.message || 'Unknown error occurred'
      dispatch({type: MANAGE_ROLES_FAIL, payload: errorMessage})
    }
  }

// manage permissions
export const managePermission =
  ({payload, roleId, callback}) =>
  async (dispatch) => {
    dispatch({type: MANAGE_PERMISSIONS})
    try {
      const response = await callApi({
        url: permission_store + '/' + roleId,
        payload,
        method: 'POST',
      })
      dispatch(getPermissions(roleId, false))
      callback()
      dispatch({type: MANAGE_PERMISSIONS_SUCCESS, payload: response.data})
    } catch (error) {
      const errorMessage = error.response.data.error.message || 'Unknown error occurred'
      dispatch({type: MANAGE_PERMISSIONS_FAIL, payload: errorMessage})
    }
  }

// ----------------------- Auth Users --------------------- //
export const getStaff = () => async (dispatch) => {
  dispatch({type: GET_STAFF})
  try {
    const response = await callApi({url: get_staff, method: 'GET'})
    dispatch({type: GET_STAFF_SUCCESS, payload: response.data})
  } catch (error) {
    dispatch({type: GET_STAFF_FAIL, payload: error}) // Dispatching an action if there's an error
  }
}

// manage  staff
export const manageStaff =
  ({payload, staffId, method, purpose, callback}) =>
  async (dispatch) => {
    dispatch({type: MANAGE_STAFF})
    try {
      const formData = new FormData()
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key])
      })
      const getUrls = () => {
        switch (purpose) {
          case 'create':
            return manage_staff

          case 'status':
            return manage_staff_status + '/' + staffId

          default:
            return manage_staff + '/' + staffId
        }
      }
      const response = await callApi({
        url: getUrls(),
        payload: ['post', 'put'].includes(method) ? formData : payload,
        method,
      })
      dispatch(getStaff())
      dispatch({type: MANAGE_STAFF_SUCCESS, payload: response.data})
      callback(response)
    } catch (error) {
      const errorMessage = error.response.data.error.message || 'Unknown error occurred'
      dispatch({type: MANAGE_STAFF_FAIL, payload: errorMessage})
    }
  }
