import {KTIcon} from '../../_metronic/helpers'
import {FC} from 'react'

const TableActions2 = ({
  handleUpdate,
  handleDelete,
  handleViewSubCategory,
  handleView,
  handleEventDelete,
  handleViewEvents,
  handleDeclareResult,
  handleNotify,
}) => {
  return (
    <div className='menu-gray-600 menu-state-bg-light-primary fw-bold w-200px' data-kt-menu='true'>
      <div className='separator mb-3 opacity-75'></div>
      <div className='menu-item px-3'>
        {/* handle view icon */}
        {handleView?.show && (
          <button
            onClick={() => handleView?.event()}
            className='btn btn-icon btn-hover-bg-success btn-active-color-primary btn-outline-secondary  btn-sm w-100 d-flex align-items-center justify-content-start px-3 py-6 gap-2  '
          >
            <KTIcon iconName='eye' className='fs-1' />
            <h6 className='mb-0'>View</h6>
          </button>
        )}
      </div>
      <div className='menu-item px-3'>
        {/* handle update icon */}
        {handleUpdate?.show && (
          <button
            onClick={() => handleUpdate?.event()}
            className='btn btn-icon  btn-active-bg-primary btn-active-color-primary btn-outline-secondary   btn-sm w-100 d-flex align-items-center justify-content-start  px-3 py-6 gap-2  me-1'
          >
            <KTIcon iconName='pencil' className='fs-1' />
            <h6 className='mb-0'> Edit</h6>
          </button>
        )}
      </div>
      <div className='menu-item px-3'>
        {handleDelete?.show && (
          <button
            onClick={() => handleDelete?.event()}
            className='btn btn-icon  btn-active-bg-primary btn-active-color-primary btn-outline-secondary   btn-sm w-100 d-flex align-items-center justify-content-start  px-3 py-6 gap-2  me-1'
          >
            <KTIcon iconName='trash' className='fs-1' />
            <h6 className='mb-0'> Delete</h6>
          </button>
        )}
      </div>
      <div className='menu-item px-3'>
        {/* handle view icon */}
        {handleViewEvents?.show && (
          <button
            onClick={() => handleViewEvents?.event()}
            className='btn btn-icon btn-hover-bg-success btn-active-color-primary btn-outline-secondary  btn-sm w-100 d-flex align-items-center justify-content-start px-3 py-6 gap-2  '
          >
            <KTIcon iconName='eye' className='fs-1' />
            <h6 className='mb-0'>View Events</h6>
          </button>
        )}
      </div>
      <div className='menu-item px-3'>
        {/* handle view icon */}
        {handleNotify?.show && (
          <button
            onClick={() => handleNotify?.event()}
            className='btn btn-icon btn-hover-bg-success btn-active-color-primary btn-outline-secondary  btn-sm w-100 d-flex align-items-center justify-content-start px-3 py-6 gap-2  '
          >
            <KTIcon iconName='satellite' className='fs-1' />
            <h6 className='mb-0'>Send Notification</h6>
          </button>
        )}
      </div>

      <div className='menu-item px-3'>
        {/* handle view icon */}
        {handleDeclareResult?.show && (
          <button
            onClick={() => handleDeclareResult?.event()}
            className='btn btn-icon btn-hover-bg-success btn-active-color-primary btn-outline-secondary  btn-sm w-100 d-flex align-items-center justify-content-start px-3 py-6 gap-2  '
          >
            <KTIcon iconName='eye' className='fs-1' />
            <h6 className='mb-0'>Declare Result</h6>
          </button>
        )}
      </div>

      {handleViewSubCategory?.show && (
        <>
          <div className='menu-item px-3'>
            {/* handle view icon */}
            <button
              onClick={() => handleViewSubCategory?.event()}
              className='btn btn-icon btn-hover-bg-success btn-active-color-primary btn-outline-secondary  btn-sm w-100 d-flex align-items-center justify-content-start px-3 py-6 gap-2  '
            >
              <KTIcon iconName='eye' className='fs-1' />
              <h6 className='mb-0'>View Subcategory</h6>
            </button>
          </div>
          <div className='separator mt-3 opacity-75'></div>
        </>
      )}

      {handleEventDelete?.show && (
        <div>
          <div className='menu-item px-3'>
            <button
              onClick={() => handleEventDelete?.event()}
              className='btn btn-icon  btn-active-bg-primary btn-active-color-primary btn-outline-secondary   btn-sm w-100 d-flex align-items-center justify-content-start  px-3 py-6 gap-2  me-1'
            >
              <i className='text-gray-400 fas fa-ban fs-2'></i>

              <h6 className='mb-0'>Cancel Event</h6>
            </button>
          </div>
          <div className='separator mt-3 opacity-75'></div>
        </div>
      )}
    </div>
  )
}

export default TableActions2
