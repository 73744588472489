export const priceSymbol = '₹'
const Price = ({value, className = ''}) => {
  return (
    <div className={`${className}`}>
      <div>{priceSymbol}</div>
      {Number(value).toFixed(2)}
    </div>
  )
}

export default Price
