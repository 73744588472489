// fetch users
export const FETCH_USERS = 'FETCH_USERS'
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'

export const REMOVE_USERS = 'REMOVE_USERS'
export const REMOVE_USERS_FAIL = 'REMOVE_USERS_FAIL'
export const REMOVE_USERS_SUCCESS = 'REMOVE_USERS_SUCCESS'

export const FETCH_BET_LOGS = 'FETCH_BET_LOGS'
export const FETCH_BET_LOGS_FAIL = 'FETCH_BET_LOGS_FAIL'
export const FETCH_BET_LOGS_SUCCESS = 'FETCH_BET_LOGS_SUCCESS'

export const FETCH_EVENT_BET_LOG = 'FETCH_EVENT_BET_LOG'
export const FETCH_EVENT_BET_LOG_FAIL = 'FETCH_EVENT_BET_LOG_FAIL'
export const FETCH_EVENT_BET_LOG_SUCCESS = 'eventBetlogsData_SUCCESS'



// --------------DASHBOARD ------------//

export const FETCH_DASHBOARD = 'FETCH_DASHBOARD'
export const FETCH_DASHBOARD_FAIL = 'FETCH_DASHBOARD_FAIL'
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_EVENTS_SUCCESS'

// -------------- EVENTS ------------//

export const FETCH_EVENTS = 'FETCH_EVENTS'
export const FETCH_EVENTS_FAIL = 'FETCH_EVENTS_FAIL'
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS'

export const GENERATE_QUESTIONS = 'GENERATE_QUESTIONS'
export const GENERATE_QUESTIONS_FAIL = 'GENERATE_QUESTIONS_FAIL'
export const GENERATE_QUESTIONS_SUCCESS = 'GENERATE_QUESTIONS_SUCCESS'

export const MANAGE_EVENTS = 'MANAGE_EVENTS'
export const MANAGE_EVENTS_FAIL = 'MANAGE_EVENTS_FAIL'
export const MANAGE_EVENTS_SUCCESS = 'MANAGE_EVENTS_SUCCESS'


export const EVENT_INPUT = 'EVENT_INPUT'
export const EVENT_INPUT_FAIL = 'EVENT_INPUT_FAIL'
export const EVENT_INPUT_SUCCESS = 'EVENT_INPUT_SUCCESS'

export const EVENT_SOURCES = 'EVENT_SOURCES'
export const EVENT_SOURCES_FAIL = 'EVENT_SOURCES_FAIL'
export const EVENT_SOURCES_SUCCESS = 'EVENT_SOURCES_SUCCESS'

export const DECLARE_RESULT = 'DECLARE_RESULT'
export const DECLARE_RESULT_FAIL = 'DECLARE_RESULT_FAIL'
export const DECLARE_RESULT_SUCCESS = 'DECLARE_RESULT_SUCCESS'

// Cricket
export const CRICKET_SOURCES = 'CRICKET_SOURCES'
export const CRICKET_SOURCES_FAIL = 'CRICKET_SOURCES_FAIL'
export const CRICKET_SOURCES_SUCCESS = 'CRICKET_SOURCES_SUCCESS'


// Cricket Tours
export const CRICKET_TOURS = 'CRICKET_TOURS'
export const CRICKET_TOURS_FAIL = 'CRICKET_TOURS_FAIL'
export const CRICKET_TOURS_SUCCESS = 'CRICKET_TOURS_SUCCESS'

// Series Tours 
export const SERIES_TOURS = 'SERIES_TOURS'
export const SERIES_TOURS_FAIL = 'SERIES_TOURS_FAIL'
export const SERIES_TOURS_SUCCESS = 'SERIES_TOURS_SUCCESS'

// upcoming matches
export const UPCOMING_MATCHES = 'UPCOMING_MATCHES'
export const UPCOMING_MATCHES_FAIL = 'UPCOMING_MATCHES_FAIL'
export const UPCOMING_MATCHES_SUCCESS = 'UPCOMING_MATCHES_SUCCESS'

// upcoming tours
export const UPCOMING_TOURS = 'UPCOMING_TOURS'
export const UPCOMING_TOURS_FAIL = 'UPCOMING_TOURS_FAIL'
export const UPCOMING_TOURS_SUCCESS = 'UPCOMING_TOURS_SUCCESS'


export const EVENT_ORDER = 'EVENT_ORDER'
export const EVENT_ORDER_FAIL = 'EVENT_ORDER_FAIL'
export const EVENT_ORDER_SUCCESS = 'EVENT_ORDER_SUCCESS'

export const GENERATE_RULES = 'GENERATE_RULES'
export const GENERATE_RULES_FAIL = 'GENERATE_RULES_FAIL'
export const GENERATE_RULES_SUCCESS = 'GENERATE_RULES_SUCCESS'

// 
export const CLEAR_EVENT_INPUT = 'CLEAR_EVENT_INPUT';
// -------------- Categories ------------//

export const FETCH_CATEGORIES = 'FETCH_CATEGORIES'
export const FETCH_CATEGORIES_FAIL = 'FETCH_CATEGORIES_FAIL'
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS'

export const MANAGE_CATEGORIES = 'MANAGE_CATEGORIES'
export const MANAGE_CATEGORIES_FAIL = 'MANAGE_CATEGORIES_FAIL'
export const MANAGE_CATEGORIES_SUCCESS = 'MANAGE_CATEGORIES_SUCCESS'

// -------------- Sub Categories ------------//

export const SUB_CATEGORIES = 'SUB_CATEGORIES'
export const SUB_CATEGORIES_SUCCESS = 'SUB_CATEGORIES_SUCCESS'
export const SUB_CATEGORIES_FAIL = 'SUB_CATEGORIES_FAIL'

// -------------- BANNER ------------//

export const BANNER = 'BANNER'
export const BANNER_SUCCESS = 'BANNER_SUCCESS'
export const BANNER_FAIL = 'BANNER_FAIL'

// -------------- SETTINGS ------------//

export const GET_SETTINGS = 'GET_SETTINGS'
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS'
export const GET_SETTINGS_FAIL = 'GET_SETTINGS_FAIL'

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS'
export const UPDATE_SETTINGS_FAIL = 'UPDATE_SETTINGS_FAIL'

// Roles & Permissions
export const GET_ROLES = 'GET_ROLES'
export const GET_ROLES_FAIL = 'GET_ROLES_FAIL'
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS'

export const MANAGE_ROLES = 'MANAGE_ROLES'
export const MANAGE_ROLES_FAIL = 'MANAGE_ROLES_FAIL'
export const MANAGE_ROLES_SUCCESS = 'MANAGE_ROLES_SUCCESS'

export const GET_PERMISSIONS = 'GET_PERMISSIONS'
export const GET_PERMISSIONS_FAIL = 'GET_PERMISSIONS_FAIL'
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS'

export const MANAGE_PERMISSIONS = 'MANAGE_PERMISSIONS'
export const MANAGE_PERMISSIONS_FAIL = 'MANAGE_PERMISSIONS_FAIL'
export const MANAGE_PERMISSIONS_SUCCESS = 'MANAGE_PERMISSIONS_SUCCESS'

// Get Staffs 
export const GET_STAFF = 'GET_STAFF'
export const GET_STAFF_FAIL = 'GET_STAFF_FAIL'
export const GET_STAFF_SUCCESS = 'GET_STAFF_SUCCESS'

export const MANAGE_STAFF = 'MANAGE_STAFF'
export const MANAGE_STAFF_FAIL = 'MANAGE_STAFF_FAIL'
export const MANAGE_STAFF_SUCCESS = 'MANAGE_STAFF_SUCCESS'


// Get USER
export const USER = 'USER'
export const USER_FAIL = 'USER_FAIL'
export const USER_SUCCESS = 'USER_SUCCESS'


// TRANSCATIONS
export const TRANSACTIONS = 'TRANSACTIONS'
export const TRANSACTIONS_FAIL = 'TRANSACTIONS_FAIL'
export const TRANSACTIONS_SUCCESS = 'TRANSACTIONS_SUCCESS'


//NOTIFICATIONS
export const NOTIFICATIONS = 'NOTIFICATIONS'
export const NOTIFICATIONS_FAIL = 'NOTIFICATIONS_FAIL'
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS'
