import {FC, Fragment, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {DisableSidebar} from '../../_metronic/layout/core'
import {WithChildren} from '../../_metronic/helpers'

const PrivateRoutes = () => {
  const EventPage = lazy(() => import('../modules/events/EventPage'))
  const EventAssetsPage = lazy(() => import('../modules/eventAsstes/EventAssetsPage'))
  const CricketAssetsPage = lazy(() => import('../modules/cricketAssets/CricketAssetsPage'))
  const CategoriesPage = lazy(() => import('../modules/categories/CategoriesPage'))
  const CricketTours = lazy(()=>import ('../modules/cricket-tours/cricketTours'))
  const SubCategoriesPage = lazy(() => import('../modules/subCategories/SubCategories'))
  const BannersPage = lazy(() => import('../modules/banners/BannersPage'))
  const UsersPage = lazy(() => import('../modules/users/UserPage'))
  const TransactionsPage = lazy(() => import('../modules/transaction/TransactionPage'))
  const PermissionsPage = lazy(() => import('../modules/admins/permissions/PermissionPage'))
  const RolesPage = lazy(() => import('../modules/admins/roles/RolePage'))
  const AuthUserPage = lazy(() => import('../modules/admins/auth-users/AuthUserPage'))
  const LogsPage = lazy(() => import('../modules/bet-logs/logsPage'))
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))

  const allPages = [
    {isSuspense: true, path: 'events/*', component: <EventPage />},
    {isSuspense: true, path: 'event-assets/*', component: <EventAssetsPage />},
    {isSuspense: true, path: 'cricket-assets/*', component: <CricketAssetsPage />},
    {isSuspense: true, path: 'banner/*', component: <BannersPage />},
    {isSuspense: true, path: 'cricket-tours/*', component: <CricketTours />},
    {isSuspense: true, path: 'users/*', component: <UsersPage />},
    {isSuspense: true, path: '/transactions/logs/*', component: <TransactionsPage />},
    {isSuspense: false, path: 'dashboard', component: <DashboardWrapper />},
    {isSuspense: false, path: '*', component: <Navigate to='/error/404' />},
    {isSuspense: true, path: 'categories/*', component: <CategoriesPage />},
    {isSuspense: false, path: 'auth/*', component: <Navigate to='/dashboard' />},
    {isSuspense: true, path: 'subcategories/*', component: <SubCategoriesPage />},
    {isSuspense: true, path: '/admin/permissions/*', component: <PermissionsPage />},
    {isSuspense: true, path: '/admin/roles/*', component: <RolesPage />},
    {isSuspense: true, path: '/admin/auth-users/*', component: <AuthUserPage />},
    {isSuspense: true, path: '/bet-logs/*', component: <LogsPage />},
    // temperary
    {isSuspense: true, path: '/widgets/*', component: <WidgetsPage />},
    {isSuspense: true, path: '/account/*', component: <AccountPage />},
    {isSuspense: true, path: '/pages/wizards/*', component: <WizardsPage />},
  ]

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {allPages.map((item) => {
          return (
            <Fragment key={item.path}>
              <Route
                path={item.path}
                element={
                  item.isSuspense ? <SuspensedView>{item.component}</SuspensedView> : item.component
                }
              />
            </Fragment>
          )
        })}
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {'0': baseColor},
    barThickness: 1,
    shadowBlur: 5,
  })

  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  )
}

export {PrivateRoutes}
