import {
  CRICKET_SOURCES,
  CRICKET_SOURCES_FAIL,
  CRICKET_SOURCES_SUCCESS,
  UPCOMING_MATCHES,
  UPCOMING_MATCHES_FAIL,
  UPCOMING_MATCHES_SUCCESS,
  UPCOMING_TOURS,
  UPCOMING_TOURS_FAIL,
  UPCOMING_TOURS_SUCCESS,
} from '../actions'

const intialState = {
  loading: false,
  fetchLoading: false,
  cricketSources: {},
  upcomingMatch: [],
  upcomingTours: [],
}
const cricketSourcersReducer = (state = intialState, action) => {
  switch (action.type) {
    case CRICKET_SOURCES:
      return {...state, fetchLoading: true, cricketSources: {}}
    case CRICKET_SOURCES_SUCCESS:
      return {...state, fetchLoading: false, cricketSources: action?.payload}
    case CRICKET_SOURCES_FAIL:
      return {...state, fetchLoading: false}
    // upcoming
    case UPCOMING_MATCHES:
      return {...state, loading: true, upcomingMatch: {}}
    case UPCOMING_MATCHES_SUCCESS:
      return {...state, loading: false, upcomingMatch: action?.payload}
    case UPCOMING_MATCHES_FAIL:
      return {...state, loading: false}
    // upcoming tours
    case UPCOMING_TOURS:
      return {...state, loading: true, upcomingTours: {}}
    case UPCOMING_TOURS_SUCCESS:
      return {...state, loading: false, upcomingTours: action?.payload}
    case UPCOMING_TOURS_FAIL:
      return {...state, loading: false}
    default:
      return state
  }
}

export default cricketSourcersReducer
