import {EVENT_SOURCES, EVENT_SOURCES_FAIL, EVENT_SOURCES_SUCCESS} from '../actions'

const intialState = {
  loading: false,
  fetchLoading: false,
  eventSources: {},
}
const eventSourcersReducer = (state = intialState, action) => {
  switch (action.type) {
    case EVENT_SOURCES:
      return {...state, fetchLoading: true, eventSources: {}}
    case EVENT_SOURCES_SUCCESS:
      return {...state, fetchLoading: false, eventSources: action?.payload}
    case EVENT_SOURCES_FAIL:
      return {...state, fetchLoading: false}
    default:
      return state
  }
}

export default eventSourcersReducer
