import PropTypes from 'prop-types'

const CardComponent = ({iconName, title, price, bgColor, iconBg, iconSvg, loading}) => {
  return (
    <>
      <div className='card text-center' aria-hidden='true' style={{backgroundColor: bgColor}}>
        <div class='card-body p-0'>
          <div className='d-flex align-items-center sm:mb-2 gap-2 justify-content-between px-5 py-6'>
            <div className='m-0 d-flex align-items-center gap-2 card-title'>
              {iconSvg ? (
                <div className={` rounded ${iconBg}`}>
                  <img src={iconSvg} alt={title} className={`w-40px p-2 rounded `} />
                </div>
              ) : (
                <i
                  className={`text-black p-2 rounded  bi bi-${iconName} ${iconBg}`}
                  style={{fontSize: '2rem'}}
                ></i>
              )}
              <h6 className='card-title pt-2'>{title}</h6>
            </div>
            {!loading ? (
              <h3 className='card-text'>{price || 0}</h3>
            ) : (
              <>
                <h1 class='w-25 h-25 rounded placeholder-glow'>
                  <span class='placeholder h-30px  rounded col-12'> </span>
                </h1>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

CardComponent.propTypes = {
  iconName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  bgColor: PropTypes.string,
}

CardComponent.defaultProps = {
  bgColor: '#f8f9fa',
}

export default CardComponent
