import {
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_FAIL,
  FETCH_CATEGORIES_SUCCESS,
  MANAGE_CATEGORIES,
  MANAGE_CATEGORIES_FAIL,
  MANAGE_CATEGORIES_SUCCESS,
} from '../actions/index'

const intialState = {
  loading: false,
  fetchLoading: false,
  categoriesData: [],
  subCategoriesData: [],
}

const categoriesReducer = (state = intialState, action) => {
  switch (action.type) {
    // fetch categories
    case FETCH_CATEGORIES:
      return {...state, fetchLoading: true}
    case FETCH_CATEGORIES_SUCCESS:
      const key = Object.keys(action?.payload)[0]
      return {...state, fetchLoading: false, [key]: action?.payload[key]}
    case FETCH_CATEGORIES_FAIL:
      return {...state, fetchLoading: false}

    // manage categories
    case MANAGE_CATEGORIES:
      return {...state, loading: true}
    case MANAGE_CATEGORIES_SUCCESS:
      return {...state, loading: false}
    case MANAGE_CATEGORIES_FAIL:
      return {...state, loading: false}
    default:
      return state
  }
}

export default categoriesReducer
