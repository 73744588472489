import {useSelector} from 'react-redux'

const userData = localStorage.getItem('user')
const {user} = userData ? JSON.parse(userData) : {user: {}}
export const {staffRole} = user || {staffRole: null}

// to check particular method
export const IsActToRolePermitted = (resource, action) => {
  const {allPermissions} = useSelector((state) => state.rolesReducer)
  const allActions = allPermissions.find((item) => item.resource == resource)
  return staffRole ? allActions?.actions?.includes(action) : true
}

// check for sidebar menus
const FindPermission = (menu, allPermissions) => {
  const {title, subItems} = menu
  const allNested = subItems ? subItems?.map((val) => val.title) : []

  const isPermitted = allPermissions.find((item) => {
    if (subItems) {
      return allNested.includes(item.resource)
    } else {
      return item.resource.toLowerCase() === title?.toLowerCase()
    }
  })

  return staffRole ? (isPermitted ? isPermitted?.actions?.length > 0 : false) : true
}

export default FindPermission
