import {useEffect, useRef, memo} from 'react'

function TradingViewWidget() {
  const container = useRef()
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js'
    script.type = 'text/javascript'
    script.async = true
    script.innerHTML = `
      {
        "autosize": true,
        "symbol": "BINANCE:ETHUSDT",
        "interval": "1",
        "timezone": "Asia/Kolkata",
        "theme": "dark",
        "style": "1",
        "locale": "en",
        "hide_top_toolbar": false,
        "hide_legend": false,
        "allow_symbol_change": true,
        "calendar": false,
        "enabled_features": ["header_fullscreen_button", "header_undo_redo", "header_screenshot", "header_resolutions"],
        "disabled_features": ["use_localstorage_for_settings"],
        "studies": [],
        "timeframes": [
          {
            "text": "1M",
            "resolution": "1",
            "description": "1 Minute"
          },
          {
            "text": "5M",
            "resolution": "5",
            "description": "5 Minutes"
          },
          {
            "text": "15M",
            "resolution": "15",
            "description": "15 Minutes"
          },
          {
            "text": "1H",
            "resolution": "60",
            "description": "1 Hour"
          },
          {
            "text": "1D",
            "resolution": "D",
            "description": "1 Day"
          },
          {
            "text": "1W",
            "resolution": "W",
            "description": "1 Week"
          },
          {
            "text": "1M",
            "resolution": "M",
            "description": "1 Month"
          }
        ],
        "support_host": "https://in.tradingview.com"
      }`
    container.current.appendChild(script)
  }, [])

  return (
    <div
      className='tradingview-widget-container'
      ref={container}
      style={{height: '100%', width: '100%'}}
    >
      <div
        className='tradingview-widget-container__widget'
        style={{height: 'calc(100% - 32px)', width: '100%'}}
      ></div>
    </div>
  )
}

export default memo(TradingViewWidget)
