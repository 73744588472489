import {
  CRICKET_TOURS,
  CRICKET_TOURS_FAIL,
  CRICKET_TOURS_SUCCESS,
  SERIES_TOURS,
  SERIES_TOURS_FAIL,
  SERIES_TOURS_SUCCESS,
} from '../actions'

const intialState = {
  loading: false,
  fetchLoading: false,
  toursData: [],
  seriesToursData: [],
}
const cricketToursReducer = (state = intialState, action) => {
  switch (action.type) {
    case CRICKET_TOURS:
      return {...state, fetchLoading: true, toursData: {}}
    case CRICKET_TOURS_SUCCESS:
      return {...state, fetchLoading: false, toursData: action?.payload}
    case CRICKET_TOURS_FAIL:
      return {...state, fetchLoading: false}

    case SERIES_TOURS:
      return {...state, fetchLoading: true, seriesToursData: {}}
    case SERIES_TOURS_SUCCESS:
      return {...state, fetchLoading: false, seriesToursData: action?.payload}
    case SERIES_TOURS_FAIL:
      return {...state, fetchLoading: false}

    default:
      return state
  }
}

export default cricketToursReducer
