import React, {memo, useState} from 'react'
import {Badge} from 'react-bootstrap'

const Chips = ({value}) => {

  const badgeProps = {
    running: {bg: 'warning'},
    upcoming: {bg: 'secondary'},
    closed: {bg: 'danger', text: 'light'},
    unmatched: {bg: 'warning', text: 'light'},
    matched: {bg: 'success', text: 'light'},
    cancelled: {bg: 'danger', text: 'light'},
    closed: {bg: 'danger', text: 'light'},
    exiting: {bg: 'warning', text: 'light'},
    true: {bg: 'success', text: 'light'},
    false: {bg: 'danger', text: 'light'},
  }

  const getColor = () => {
    switch (value) {
      case 'running':
        return 'text-dark-yellow bg-light-warning'
      case 'upcoming':
        return 'text-primary bg-light-primary'
      case 'closed':
        return 'text-danger bg-light-danger'
      case 'unmatched':
        return 'text-warning bg-light-warning'
      case 'matched':
        return 'text-success bg-light-success'
      case 'cancelled':
        return 'text-danger bg-light-danger'
      case 'exiting':
        return 'text-warning bg-light-warning'
      case 'true':
        return 'text-success bg-light-success'
      case 'false':
        return 'text-danger bg-light-danger'
      case 'success':
        return 'text-success bg-light-primary'
      case 'pending':
        return 'text-warning bg-warning'
      case 'failed':
        return 'text-danger bg-danger'
      case 'rejected':
        return 'text-warning bg-warning'
      default:
        return 'text-primary bg-light-secondary'
    }

  }

  return (
    <div className={`d-inline-block px-3 text-capitalize py-1 text-xs fw-bold rounded-1 ${getColor()}`}>
      {value}
    </div>
  )
}

export default Chips
