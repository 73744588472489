import ForLightMode from '../../component/FetchMode'
import CardComponent from '../../component/CommonCard'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {EnableSidebar, PageTitle} from '../../../_metronic/layout/core'
import {useDispatch, useSelector} from 'react-redux'
import {getDashboard} from '../../redux/actions/othersAction'
import {useEffect, useState} from 'react'
import EventsTable from '../../component/tables/EventsTable'
import {getEvents, updateEvents} from '../../redux/actions/events'
import {priceSymbol} from '../../component/Price'

const DashboardPage = () => {
  const isForLight = ForLightMode()
  const dispatch = useDispatch()
  const data = useSelector((state) => state?.otherReducer?.dashboardData)
  const {paymentStatistics, thisMonthStatistics, tradingUserStatistics} = data || {}
  const [params, setParams] = useState({page: 1, limit: 4, status: 'running', search: ''})
  const {eventsList, fetchLoading, eventInputData} = useSelector((state) => state?.eventsReducer)
  const {events, ...pagination} = eventsList

  const betProfit =
    Number(thisMonthStatistics?.betAmount) -
    Number(thisMonthStatistics?.betRefundAmount) -
    Number(thisMonthStatistics?.betWinAmount)


  // Total data cards
  const totalData = [
    {
      iconName: 'people',
      title: 'Total Users',
      price: `${data?.totalUsers}`,
      bgColor: isForLight ? '#f8d7da' : '',
    },
    {
      iconName: 'person-check',
      title: 'Active Users',
      price: `${data?.activeUsers}`,
      bgColor: isForLight ? '#CBF0F4' : '',
    },
    {
      iconName: 'people',
      title: 'Join User',
      price: `${data?.joinedUsers}`,
      bgColor: isForLight ? '#CBD4F4' : '',
    },
    {
      iconName: 'bank',
      title: 'User Fund',
      price: `${data?.userFund?.toFixed(0)}`,
      bgColor: isForLight ? '#F6E5CA' : '',
    },
  ]

  // payment statistics
  const paymentStats = [
    {
      iconName: 'currency-rupee',
      title: 'Today Comm.',
      price: `${paymentStatistics?.todaysCommission}`,
    },
    {
      iconName: 'wallet',
      title: 'This Week',
      price: `${paymentStatistics?.thisWeekCommission}`,
    },
    {
      iconName: 'briefcase',
      title: 'This Month',
      price: `${paymentStatistics?.thisMonthCommission}`,
    },
    {
      iconName: 'calculator',
      title: 'Total',
      price: `${paymentStatistics?.totalCommission}`,
    },
  ]

  // User Statistics
  const UserStats = [
    {
      iconSvg: `${toAbsoluteUrl('/media/svg/dashboard/profit1.svg')}`,
      title: 'Credited',
      price: `${tradingUserStatistics?.totalCredit}`,
    },
    {
      iconSvg: `${toAbsoluteUrl('/media/svg/dashboard/loss.svg')}`,
      title: 'Debited',
      price: `${tradingUserStatistics?.totalDebit}`,
    },
    {
      iconSvg: `${toAbsoluteUrl('/media/svg/dashboard/profit.svg')}`,
      title: 'Profit/Loss',
      price: `${tradingUserStatistics?.actualPnl}`,
    },
    {
      iconSvg: `${toAbsoluteUrl('/media/svg/dashboard/wallet.svg')}`,
      title: 'Invested',
      price: `${tradingUserStatistics?.totalMatchedOrderInvestment}`,
    },
  ]

  // Bet Amount
  const monthStats = [
    {
      iconSvg: `${toAbsoluteUrl('/media/svg/dashboard/finance.svg')}`,
      title: 'Bet Amount',
      price: `${thisMonthStatistics?.betAmount}`,
    },
    {
      iconSvg: `${toAbsoluteUrl('/media/svg/dashboard/crown-2.svg')}`,
      title: 'Bet Win',
      price: `${thisMonthStatistics?.betWinAmount}`,
    },
    {
      iconSvg: `${toAbsoluteUrl('/media/svg/dashboard/bill.svg')}`,
      title: 'Bet Refund',
      price: `${thisMonthStatistics?.betRefundAmount}`,
    },
    {
      iconSvg: `${toAbsoluteUrl('/media/svg/dashboard/call.svg')}`,
      title: 'Invested',
      price: `${betProfit}`,
    },
  ]

  const handleStatusChange = (event) => {
    const callback = () => {
      dispatch(getDashboard())
    }
    const {id, checked} = event.target
    const payload = {bettingStopped: checked}
    dispatch(updateEvents({id, payload, params, callback}))
  }

  const notShow = ['isTrending', 'isLive', '_id', 'parent', '__v', 'icon']

  const headData =
    Array.isArray(events) && events.length > 0
      ? Object.keys(events[0]).filter((key) => !notShow.includes(key))
      : []

  const formatViewsRate = (price) => {
    if (price >= 1e9) {
      return `${(price / 1e9).toFixed(2)}B`
    } else if (price >= 1e6) {
      return `${(price / 1e6).toFixed(2)}M`
    } else if (price >= 1e3) {
      return price.toString()
    }
    return price.toString()
  }

  useEffect(() => {
    dispatch(getDashboard())
    dispatch(getEvents(params))
  }, [dispatch])

  return (
    <>
      {/* Total Data Cards */}
      <div className='row g-4 mb-8'>
        {totalData?.map((item) => {
          return (
            <div key={item.title} className='col-12 col-sm-6 col-md-3'>
              <CardComponent
                iconBg={isForLight ? 'bg-white' : 'bg-light text-white'}
                iconName={item.iconName}
                loading={fetchLoading}
                title={item.title}
                price={`${item.title === 'User Fund' ? priceSymbol : ''}  ${formatViewsRate(
                  item?.price
                )}`}
                bgColor={item.bgColor}
              />
            </div>
          )
        })}
      </div>

      {/* Commision Statistics */}
      <div className='row g-4 mb-8'>
        <div className='mb-4 border-2 border-bottom'>
          <h3>Commision Statistics</h3>
        </div>
        {paymentStats?.map((item) => {
          return (
            <div key={item.title} className='col-12 col-sm-6 col-md-3'>
              <CardComponent
                iconBg={isForLight ? 'bg-secondary' : 'bg-light text-white'}
                iconName={item.iconName}
                title={item.title}
                loading={fetchLoading}
                price={`${priceSymbol} ${Number(formatViewsRate(item?.price)).toFixed(2)}`}
                // price={<Price value={item?.price} className='d-flex gap-1' />}
                bgColor=''
              />
            </div>
          )
        })}
      </div>

      {/* Trading User Data */}
      <div className='row g-4 my-8'>
        <div className='mb-4 border-2 border-bottom'>
          <h3>
            Opinion User {' '}
            <span>
              Balance :{' '}
              {tradingUserStatistics?.userBalance && (
                <>
                  {priceSymbol} {Number(tradingUserStatistics?.userBalance).toFixed(2)}
                </>
              )}
            </span>
          </h3>
        </div>
        {UserStats?.map((item, index) => {
          return (
            <div key={index} className='col-12 col-sm-6 col-md-3'>
              <CardComponent
                iconBg={isForLight ? 'bg-secondary' : 'bg-light text-white'}
                iconSvg={item.iconSvg}
                title={item.title}
                loading={fetchLoading}
                price={`${priceSymbol}${Number(formatViewsRate(item?.price)).toFixed(2)}`}
                // price={<Price value={item?.price} className='d-flex gap-1' />}
                bgColor=''
              />
            </div>
          )
        })}
      </div>

      {/* Month Statistics */}

      {/* <div className='row g-4 my-8'>
        <div className='mb-4 border-2 border-bottom'>
          <h3> This Month Bet Statistics</h3>
        </div>
        {monthStats?.map((item, index) => {
          return (
            <div key={index} className='col-12 col-sm-6 col-md-3'>
              <CardComponent
                iconBg={isForLight ? 'bg-secondary' : 'bg-light text-white'}
                iconSvg={item.iconSvg}
                title={item.title}
                loading={fetchLoading}
                price={`${priceSymbol} ${formatViewsRate(item?.price)}`}
                // price={<Price value={item?.price} className='d-flex gap-1' />}
                bgColor=''
              />
            </div>
          )
        })}
      </div> */}

      <EventsTable
        title='Event'
        total={pagination?.total}
        className='mb-5 mb-xl-8'
        // loading={fetchLoading}
        bodyData={Array.isArray(events) ? events : []}
        headData={headData}
        handleStatusChange={handleStatusChange}
        isDashboard={true}
        params={params}
        pagination={pagination}
      />
    </>
  )
}

const DashboardWrapper = () => {
  return (
    <EnableSidebar>
      <PageTitle>Dashboard</PageTitle>
      <DashboardPage />
    </EnableSidebar>
  )
}

export {DashboardWrapper}
