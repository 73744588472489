import {
    USER,
    USER_FAIL,
    USER_SUCCESS,  } from '../actions/index'
  
  const intialState = {
    loading: false,
    fetchLoading: false,
    userData: [],
  }
  
  const userReducer = (state = intialState, action) => {
    switch (action.type) {
      // fetch categories
      case USER:
        return {...state, fetchLoading: true}
      case USER_SUCCESS:
        return {...state, fetchLoading: false, userData: action?.payload}
      case USER_FAIL:
        return {...state, fetchLoading: false}
      default:
        return state
    }
  }
  
  export default userReducer
  