import {SUB_CATEGORIES, SUB_CATEGORIES_SUCCESS, SUB_CATEGORIES_FAIL} from '../actions/index'

const intialState = {
  loading: false,
  fetchLoading:false,
  data: [],
}

const subCategoriesReducer = (state = intialState, action) => {
  switch (action.type) {
    case SUB_CATEGORIES:
      return {...state, fetchLoading: true}
    case SUB_CATEGORIES_SUCCESS:
      return {...state, fetchLoading: false, data: action?.payload}
    case SUB_CATEGORIES_FAIL:
      return {...state, fetchLoading: false}
    default:
      return state
  }
}

export default subCategoriesReducer
