import {
  systemMode,
  useThemeMode,
} from '../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

const ForLightMode = () => {
  const {mode} = useThemeMode()
  if (mode == 'system') {
    return systemMode == 'light' ? true : false
  } else {
    return mode == 'light' ? true : false
  }
}

export default ForLightMode
