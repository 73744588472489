import { useEffect } from 'react';

const Pagination = ({ handlePrev, from, to, total, isDisable = true, handleForw }) => {
  const end = Math.min(to, total);

  useEffect(() => {
    if (from >= total) {
      handlePrev();
    }
  }, [total, from, handlePrev]);

  if (total <= 0) return null;

  return (
    <div className="rounded border-gray-400 text-xs d-flex align-items-center justify-content-between">
      <div className="fs-6">
        {Math.max(0, from + 1)} - {end || 0} of {total || 0}
      </div>
      <div className="d-flex justify-content-end gap-1">
        <button
          type="button"
          onClick={handlePrev}
          className="btn btn-sm mx-2 btn-light-primary w-25 rounded-circle d-flex justify-content-center border-0 align-items-center cursor-pointer"
        >
          <i className="bi bi-arrow-left-short fs-1 text-2xl"></i>
        </button>
        <button
          disabled={!isDisable && end === total}
          type="button"
          onClick={handleForw}
          className="w-25 rounded-circle border-0 d-flex justify-content-center align-items-center btn btn-sm mx-2 btn-light-primary cursor-pointer"
        >
          <i className="bi bi-arrow-right-short fs-1 text-2xl"></i>
        </button>
      </div>
    </div>
  );
};

export default Pagination;
