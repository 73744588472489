import {
    TRANSACTIONS,
    TRANSACTIONS_FAIL,
    TRANSACTIONS_SUCCESS,  } from '../actions/index'
  
  const intialState = {
    loading: false,
    fetchLoading: false,
    transactionsData:[],
  }
  
  const transactionReducer = (state = intialState, action) => {
    switch (action.type) {
      // fetch categories
      case TRANSACTIONS:
        return {...state, fetchLoading: true}
      case TRANSACTIONS_SUCCESS:
        return {...state, fetchLoading: false, transactionsData: action?.payload}
      case TRANSACTIONS_FAIL:
        return {...state, fetchLoading: false}
      default:
        return state
    }
  }
  
  export default transactionReducer
  