import { BANNER_SUCCESS , BANNER , BANNER_FAIL} from '../actions/index'


const intialState = {
  loading: false,
  fetchLoading:false,
  bannerData: [],
}

const bannerReducer = (state = intialState, action) => {
  switch (action.type) {
    case BANNER:
      return {...state, fetchLoading: true}
    case BANNER_SUCCESS:
      return {...state, fetchLoading: false, bannerData: action?.payload}
    case BANNER_FAIL:
      return {...state, fetchLoading: false}
    default:
      return state
  }
}

export default bannerReducer
