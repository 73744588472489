import Price from '../Price'
import Chips from '../Chips'
import DateFigure from '../DateFigure'
import Pagination from '../Pagination'
import {useNavigate} from 'react-router-dom'
import {ImageUrl, KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Spinner} from 'react-bootstrap'
import CommonSearch from '../CommonSearch'
import ForLightMode from '../FetchMode'
import {IsActToRolePermitted} from '../FindPermission'
import {useEffect, useRef, useState} from 'react'
import TableActions2 from '../TableActions2'
import BackButton from '../BackButton'

export const eventStatus = [
  {label: 'Running', value: 'running'},
  {label: 'Upcoming', value: 'upcoming'},
  {label: 'Closed', value: 'closed'},
]

const EventsTable = (props) => {
  const isForLight = ForLightMode()
  const [isMenuOpen, setIsMenuOpen] = useState(null)
  const menuRef = useRef(null);

  
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(null);
    }
  };
  const toggleMenu = (index) => {
    setIsMenuOpen(isMenuOpen === index ? null : index)
  }

  const {
    title,
    handleOpen,
    handleEdit,
    handleModel,
    handlePrev,
    bodyData,
    params,
    isDashboard,
    pagination,
    handleStatusChange,
    loading,
    handleForw,
    handleParam,
  } = props
  const navigate = useNavigate() // Actions
  const pageName = 'Manage Events'
  const isCreate = IsActToRolePermitted(pageName, 'create')
  const isUpdate = IsActToRolePermitted(pageName, 'update')
  const isDelete = IsActToRolePermitted(pageName, 'delete')
  const isEventDelete = IsActToRolePermitted(pageName, 'cancel_event')
  const DeclareResult = IsActToRolePermitted(pageName, 'declare_result')
  const isGenerateQuestion = IsActToRolePermitted(pageName, 'generate_question')


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className={` mb-5 mb-xl-8`}>
      {/* header */}

      {isDashboard ? (
        <>
          <div className='mb-4 border-2 border-bottom'>
            <h3>Events</h3>
          </div>
        </>
      ) : (
        <>
          {params?.categoryId && (
            <>
              <div>
                <div className='mb-5'>
                  <BackButton event={() => navigate(-3)} />
                </div>
              </div>
            </>
          )}
          <div className='card-header d-flex  justify-content-between align-items-center'>
            {/* Buttons */}

            <div className='d-flex w-100 gap-2 flex-wrap justify-content-between align-items-center'>
              <div className=''>
                <CommonSearch onChange={(value) => handleParam('search', value)} title='Events' />
              </div>

              <div className='d-flex align-items-center gap-2'>
                {/* {isGenerateQuestion && (
                  <PrimaryButton title='Generate Question' event={() => navigate('questions')} />
                )} */}
                {isCreate && (
                  <>
                    <div
                      className='card-toolbar'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      data-bs-trigger='hover'
                      title={`Click to add ${title}`}
                    >
                      <button
                        onClick={handleOpen}
                        className={`btn btn-sm font-weight-bold d-flex align-items-center  ${
                          isForLight ? 'btn-dark' : 'btn-light'
                        } `}
                      >
                        <KTIcon iconName='plus' className='fs-1' />
                        {title}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* tabs */}
          <div className='d-flex mb-1 mt-6 gap-2  justify-content-start'>
            {eventStatus.map((item) => {
              return (
                <div
                  key={item.label}
                  style={{
                    cursor: 'pointer',
                    borderRadius: '5px 5px 0px 0px',
                    borderWidth: '1px',
                    fontWeight: '600',
                    fontSize: '14px',
                  }}
                  onClick={() => handleParam('status', item.value)}
                  className={`items-center  w-160px py-3  px-8 hover:text-primary -mb-px text-14px text-center ${
                    params.status == item.value
                      ? `${isForLight ? 'bg-dark text-light' : 'bg-white text-dark'} border-dark`
                      : 'text-muted bg-white'
                  }`}
                >
                  {item.label} {item.value == 'not_declared' && <span>(0)</span>}
                </div>
              )
            })}
          </div>
        </>
      )}

      {/* body */}

      {/* Table Data */}
      <div className='card'>
      <div className='card-body table-responsive overflow-resp'>
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0'>
          <thead>
            <tr className='pe-auto text-gray-600 fw-bold'>
              {/* <th>SNo.</th> */}
              <th>Question</th>
              <th>Category</th>
              <th>Status</th>
              <th>Rate</th>
              <th>Trade Info</th>
              {!['running'].includes(params.status) && <th>Winner</th>}
              {!['closed', 'upcoming'].includes(params.status) && <th>Betting Stopped</th>}
              {/* <th className='min-w-150px'></th> */}
              <th>Schedule</th>
              {!isDashboard && <th className='text-end'>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr className='text-center'>
                <td colSpan={9}>
                  <Spinner animation='border' className='mt-5' />
                </td>
              </tr>
            ) : pagination.total > 0 ? (
              <>
                {bodyData &&
                  bodyData?.map((item, index) => {
                    const isResultDeclared =
                      item && item.resultDeclared !== undefined ? item.resultDeclared : false
                    return (
                      <tr key={index}>
                        <td className='text-capitalize cursor-pointer'>
                          <div
                            onClick={() => {
                              navigate(`/events/${item._id}/details`)
                            }}
                            className='d-flex align-items-start'
                          >
                            <img
                              key={index}
                              width={30}
                              height={30}
                              src={
                                item.image
                                  ? `${ImageUrl}${item.image}`
                                  : toAbsoluteUrl('/media/logos/images.png')
                              }
                              className='me-4 object-fit-cover rounded-circle'
                            />
                            <p className='w-200px fw-normal text-gray-700 '>{item.question}</p>
                          </div>
                        </td>
                        <td className='text-capitalize text-gray-700 '>{item?.category?.title}</td>
                        <td className='text-capitalize'>
                          <Chips value={item?.status} />
                        </td>
                        <td className='text-capitalize  text-nowrap'>
                          <div className='d-flex gap-3'>
                            <span className='fw-bold text-gray-600'>Yes :</span>
                            <Price className='d-flex text-success fw-bold' value={item?.yesRate} />
                          </div>
                          <div className='d-flex gap-3'>
                            <span className='fw-bold text-gray-600'>No :</span>
                            <Price className='d-flex text-danger fw-bold' value={item?.noRate} />
                          </div>
                        </td>
                        <td className='text-capitalize min-w-150px text-nowrap'>
                          <div className='d-flex gap-1 fw-bold'>
                            <span className='text-gray-600'> Volume</span>:{' '}
                            <Price className='d-flex' value={item?.volume} />
                          </div>
                          <div className='d-flex gap-1 fw-bold'>
                            {' '}
                            <span className='text-gray-600'> Trades</span>: {item.trades}
                          </div>
                          <div className='d-flex gap-1 fw-bold'>
                            {' '}
                            <span className='text-gray-600'> Traders</span>: {item.userCount}
                          </div>
                        </td>
                        {['not_declared', 'upcoming', 'closed'].includes(params.status) && (
                          <td className='text-capitalize text-nowrap'>
                            <span>
                              <i
                                className={`text-warning bi ${
                                  item.result === 'not_declared'
                                    ? 'bi-hourglass-split'
                                    : ' bi-trophy-fill'
                                }`}
                              />
                              {item?.result?.replace('_', ' ')}
                            </span>
                          </td>
                        )}
                        {!['closed', 'upcoming'].includes(params.status) && (
                          <td>
                            <div className='form-check form-check-solid form-switch fv-row'>
                              <input
                                className='form-check-input w-45px h-30px'
                                type='checkbox'
                                name='bettingStopped'
                                id={item._id}
                                checked={item.bettingStopped}
                                onChange={(event) => handleStatusChange(event)}
                              />
                              <label className='form-check-label'></label>
                            </div>
                          </td>
                        )}

                        <td className='text-capitalize text-gray-700 fw-bold text-nowrap'>
                          
                          <DateFigure value={item.startDate} format='DD MMM h:mm a' />
                          <span className='text-end text-gray-500'> To</span>
                          <br></br>
                          
                          <DateFigure value={item.endTime} format='DD MMM h:mm a' />
                        </td>

                        {/* <Dropdown2 /> */}

                        {!isDashboard && (
                          <td>
                            <div className='text-center m-0 position-relative'>
                              <button
                                type='button'
                                className='btn btn-clean btn-sm btn-icon btn-icon-primary  me-n3'
                              >
                                <img
                                  onClick={() => toggleMenu(index)}
                                  className='text-gray-400'
                                  src={toAbsoluteUrl('media/icons/duotune/general/gen053.svg')}
                                />
                              </button>
                             {isMenuOpen === index && (
                                <div ref={menuRef} className='table-actions-menu show'>
                                  <TableActions2
                                    handleView={{
                                      show: true,
                                      event: () => navigate(`/events/${item._id}/details`),
                                    }}
                                    handleUpdate={{show: isUpdate, event: () => handleEdit(item)}}
                                    handleDelete={{
                                      show: isDelete,
                                      event: () => handleModel(item, 'isRemove'),
                                    }}
                                    {...(['running', 'closed'].includes(params.status) && {
                                      // !isResultDeclared &&
                                      handleDeclareResult: {
                                        show: DeclareResult,
                                        event: () => handleModel(item, 'isDeclareResult'),
                                      },
                                      handleEventDelete: {
                                        show: isEventDelete,
                                        event: () => handleModel(item, 'isCancelEvent'),
                                      },
                                    })}
                                  />
                                </div>
                              )}
                            </div>
                          </td>
                        )}
                      </tr>
                    )
                  })}
              </>
            ) : (
              <tr className='text-center  '>
                <td className='' colSpan={9}>
                  <p className=' text-gray-600 mt-10 py-3 fs-6 fw-bold px-8  -mb-px text-14px text-center '>
                    No Events Found
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      </div>

      {/* pagination */}
      {!isDashboard && (
        <div className='card-footer py-4'>
          <Pagination
            from={(params.page - 1) * pagination?.limit}
            to={params.page * pagination?.limit}
            total={pagination?.total}
            handleForw={handleForw}
            handlePrev={handlePrev}
          />
        </div>
      )}
    </div>
  )
}

export default EventsTable
