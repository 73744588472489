import {
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAIL,
  REMOVE_USERS,
  REMOVE_USERS_SUCCESS,
  REMOVE_USERS_FAIL,
  FETCH_BET_LOGS,
  FETCH_BET_LOGS_SUCCESS,
  FETCH_BET_LOGS_FAIL,
  GET_SETTINGS,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAIL,
  UPDATE_SETTINGS,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAIL,
  FETCH_DASHBOARD,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAIL,
  FETCH_EVENT_BET_LOG,
  FETCH_EVENT_BET_LOG_SUCCESS,
  FETCH_EVENT_BET_LOG_FAIL,
  NOTIFICATIONS,
  NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_FAIL,
} from '../actions/index'

const intialState = {
  loading: false,
  fetchLoading: false,
  dashboardData:{},
  usersData: {},
  betlogsData: {},
  eventBetlogsData:{},
  notification:{},
  settingData: {},
  paths: [],
}

const otherReducer = (state = intialState, action) => {
  switch (action.type) {
    // fetch users
    case FETCH_USERS:
      return {...state, fetchLoading: true}
    case FETCH_USERS_SUCCESS:
      return {...state, fetchLoading: false, usersData: action?.payload}
    case FETCH_USERS_FAIL:
      return {...state, fetchLoading: false}

    // fetch bets logs
    case FETCH_BET_LOGS:
      return {...state, fetchLoading: true}
    case FETCH_BET_LOGS_SUCCESS:
      return {...state, fetchLoading: false, betlogsData: action?.payload}
    case FETCH_BET_LOGS_FAIL:
      return {...state, fetchLoading: false}

      // 
      case FETCH_EVENT_BET_LOG:
      return {...state, fetchLoading: true}
    case FETCH_EVENT_BET_LOG_SUCCESS:
      return {...state, fetchLoading: false, eventBetlogsData: action?.payload}
    case FETCH_EVENT_BET_LOG_FAIL:
      return {...state, fetchLoading: false}

    // fetch settings
    case GET_SETTINGS:
      return {...state, fetchLoading: true}
    case GET_SETTINGS_SUCCESS:
      return {...state, fetchLoading: false, settingData: action?.payload}
    case GET_SETTINGS_FAIL:
      return {...state, fetchLoading: false}

      // fetch dashboard
      case FETCH_DASHBOARD:
        return {...state, fetchLoading: true}
      case FETCH_DASHBOARD_SUCCESS:
        return {...state, fetchLoading: false, dashboardData: action?.payload}
      case FETCH_DASHBOARD_FAIL:
        return {...state, fetchLoading: false}

    // update settings
    case UPDATE_SETTINGS:
      return {...state, loading: true}
    case UPDATE_SETTINGS_SUCCESS:
      return {...state, loading: false}
    case UPDATE_SETTINGS_FAIL:
      return {...state, loading: false}

      case NOTIFICATIONS:
        return {...state, loading: true}
      case NOTIFICATIONS_SUCCESS:
        return {...state, loading: false}
      case NOTIFICATIONS_FAIL:
        return {...state, loading: false}

    // remove users
    case REMOVE_USERS:
      return {...state, loading: true}
    case REMOVE_USERS_SUCCESS:
      return {...state, loading: false}
    case REMOVE_USERS_FAIL:
      return {...state, loading: false}

    // set pathhs to access
    case 'SET_PATHS_TOCHECK':
      const allPaths = state.paths.includes(action.payload)
        ? state.paths
        : [...state.paths, action.payload]
      return {...state, paths: allPaths}

    default:
      return state
  }
}

export default otherReducer
