import {callApi} from '../../../_metronic/helpers/api'
import {
  CANCEL_EVENTS,
  CREATE_EVENTS,
  DELETE_EVENTS,
  GET_ALL_EVENTS,
  GET_EVENT_BY_ID,
  UPDATE_EVENTS,
  create_auto_order,
  declare_result,
  event_rules_generate,
  get_all_event_sources,
  get_event_questions,
  get_event_sources,
} from '../../utils/api'
import {
  MANAGE_EVENTS,
  MANAGE_EVENTS_SUCCESS,
  MANAGE_EVENTS_FAIL,
  FETCH_EVENTS,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_FAIL,
  GENERATE_QUESTIONS_SUCCESS,
  GENERATE_QUESTIONS,
  GENERATE_QUESTIONS_FAIL,
  EVENT_INPUT,
  EVENT_INPUT_FAIL,
  EVENT_INPUT_SUCCESS,
  CLEAR_EVENT_INPUT,
  EVENT_SOURCES,
  EVENT_SOURCES_SUCCESS,
  EVENT_SOURCES_FAIL,
  EVENT_ORDER,
  EVENT_ORDER_SUCCESS,
  EVENT_ORDER_FAIL,
  GENERATE_RULES,
  GENERATE_RULES_SUCCESS,
  GENERATE_RULES_FAIL,
  DECLARE_RESULT,
  DECLARE_RESULT_SUCCESS,
  DECLARE_RESULT_FAIL,
} from './index'

// fetch events
export const getEvents = (params) => async (dispatch) => {
  dispatch({type: FETCH_EVENTS})
  try {
    const response = await callApi({url: GET_ALL_EVENTS, params, method: 'GET'})
    dispatch({type: FETCH_EVENTS_SUCCESS, payload: {eventsList: response?.data}})
  } catch (error) {
    dispatch({type: FETCH_EVENTS_FAIL, payload: error})
  }
}

// fetch event's questions
export const getEventsQuestions = (params) => async (dispatch) => {
  dispatch({type: GENERATE_QUESTIONS})
  if (params) {
    try {
      const response = await callApi({url: get_event_questions, params, method: 'GET'})
      dispatch({type: GENERATE_QUESTIONS_SUCCESS, payload: response?.data})
    } catch (error) {
      dispatch({type: GENERATE_QUESTIONS_FAIL, payload: error})
    }
  } else {
    dispatch({type: GENERATE_QUESTIONS_FAIL})
  }
}

// create new event
export const createEvents =
  ({payload, params, callback}) =>
  async (dispatch) => {
    dispatch({type: MANAGE_EVENTS})
    try {
      const response = await callApi({url: CREATE_EVENTS, payload, method: 'POST'})
      dispatch({type: MANAGE_EVENTS_SUCCESS, payload: response.data})
      callback(response)
      dispatch(getEvents(params))
    } catch (error) {
      dispatch({type: MANAGE_EVENTS_FAIL, payload: error})
    }
  }

// fetch event by id
export const getEventsById = (id, callback) => async (dispatch) => {
  dispatch({type: FETCH_EVENTS})
  try {
    const response = await callApi({url: `${GET_EVENT_BY_ID}/${id}`, method: 'GET'})
    dispatch({type: FETCH_EVENTS_SUCCESS, payload: {eventsData: response?.data}})
    callback()
  } catch (error) {
    dispatch({type: FETCH_EVENTS_FAIL, payload: error})
  }
}

// update events
export const updateEvents =
  ({id, payload, params, callback}) =>
  async (dispatch) => {
    dispatch({type: MANAGE_EVENTS})
    try {
      const response = await callApi({url: `${UPDATE_EVENTS}/${id}`, payload, method: 'PUT'})
      dispatch({type: MANAGE_EVENTS_SUCCESS, payload: response?.data})
      dispatch(getEvents(params))
      callback(response?.data)
    } catch (error) {
      dispatch({type: MANAGE_EVENTS_FAIL, payload: error})
    }
  }

// remove events
export const deleteEvents =
  ({id, callback, params, isCancelEvent}) =>
  async (dispatch) => {
    dispatch({type: MANAGE_EVENTS})
    try {
      const response = await callApi({
        url: `${isCancelEvent ? `${CANCEL_EVENTS}?eventId=${id}` : `${DELETE_EVENTS}/${id}`}`,
        method: `${isCancelEvent ? 'POST' : 'DELETE'}`,
      })
      dispatch({type: MANAGE_EVENTS_SUCCESS, payload: response?.data})
      callback(response?.data)
      dispatch(getEvents(params))
    } catch (error) {
      dispatch({type: MANAGE_EVENTS_FAIL, payload: error})
    }
  }

// event action
export const eventInputAction = (formValues, callback) => async (dispatch) => {
  dispatch({type: EVENT_INPUT})
  try {
    dispatch({type: EVENT_INPUT_SUCCESS, payload: formValues})
    if (callback) callback()
  } catch (error) {
    dispatch({type: MANAGE_EVENTS_FAIL, payload: error})
  }
}

// clear input
export const clearEventInputAction = () => ({type: CLEAR_EVENT_INPUT})

// event auto orders
export const createAutoEvents =
  ({payload, callback}) =>
  async (dispatch) => {
    dispatch({type: EVENT_ORDER})
    try {
      const response = await callApi({url: create_auto_order, payload, method: 'POST'})
      dispatch({type: EVENT_ORDER_SUCCESS, payload: response.data})
      callback(response)
    } catch (error) {
      dispatch({type: EVENT_ORDER_FAIL, payload: error})
    }
  }

// fetch event by id
export const generateRulesAction = (payload, callback) => async (dispatch) => {
  dispatch({type: GENERATE_RULES})
  try {
    const response = await callApi({
      url: `${event_rules_generate}`,
      payload: payload,
      method: 'POST',
    })
    dispatch({type: GENERATE_RULES_SUCCESS, payload: {rulesData: response?.data}})
    return callback(response)
  } catch (error) {
    dispatch({type: GENERATE_RULES_FAIL, payload: error})
  }
}

export const declareResultAction = ({payload, params , callback}) => async (dispatch) => {
  dispatch({type: DECLARE_RESULT})
  try {
    const response = await callApi({
      url: `${declare_result}`,
      payload: payload,
      params:params,
      method: 'POST',
    })
    dispatch({type: DECLARE_RESULT_SUCCESS, payload: {resultData: response?.data}})
    return callback(response)
  } catch (error) {
    dispatch({type: DECLARE_RESULT_FAIL, payload: error})
  }
}
