import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {useDispatch, useSelector} from 'react-redux'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {getPermissions} from '../../../../app/redux/actions/roles'
import {setPathsToCheck} from '../../../../app/redux/actions/othersAction'
import FindPermission, {staffRole} from '../../../../app/component/FindPermission'
import {Link, useLocation} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../helpers'
import ForLightMode from '../../../../app/component/FetchMode'

export function AsideMenuMain() {
  const intl = useIntl()
  const {pathname} = useLocation()
  const dispatch = useDispatch<any>()
  const isForLight = ForLightMode()
  const menuItems = [
    {
      to: '/dashboard',
      title: intl.formatMessage({id: 'MENU.DASHBOARD'}),
      icon: 'black-right',
      fontIcon: 'bi-app-indicator',
      subItems: null,
    },
    {
      to: '/categories',
      title: 'Manage Categories',
      icon: 'black-right',
      fontIcon: 'bi-sticky',
      subItems: [
        {to: '/categories', title: 'Categories', hasBullet: true},
        {to: '/subcategories', title: 'Sub Categories', hasBullet: true},
      ],
    },

    {
      to: '/events',
      title: 'Manage Events',
      icon: 'black-right',
      fontIcon: 'bi-sticky',
      subItems: [
        {to: '/events', title: 'Events', hasBullet: true},
        {to: '/event-assets', title: 'Event Assets', hasBullet: true},
        {to: '/cricket-assets', title: 'Cricket Assets', hasBullet: true},
        {to: '/cricket-tours', title: 'Cricket Tours', hasBullet: true},
      ],
    },
    // {
    //   to: '/events',
    //   title: 'Manage Events',
    //   icon: 'black-right',
    //   fontIcon: 'bi-layers',
    //   subItems: null,
    // },
    {
      to: '/bet-logs',
      title: 'Bet Logs',
      icon: 'black-right',
      fontIcon: 'bi-layers',
      subItems: null,
    },
    {
      to: '/users',
      title: 'Manage Users',
      icon: 'black-right',
      fontIcon: 'bi-layers',
      subItems: null,
    },
    {
      to: '/transactions/logs',
      title: 'All Transactions',
      icon: 'black-right',
      fontIcon: 'bi-sticky',
      subItems: [{to: '/transactions/logs', title: 'Transactions logs', hasBullet: true}],
    },
    {
      to: '/banner',
      title: 'Manage CMS',
      icon: 'black-right',
      fontIcon: 'bi-sticky',
      subItems: [
        {to: '/banner', title: 'Banner', hasBullet: true},
      ],
    },
    {
      to: '/admin/permissions',
      title: 'Manage Admins',
      icon: 'black-right',
      fontIcon: 'bi-sticky',
      subItems: [
        {to: '/admin/roles', title: 'Roles', hasBullet: true},
        // Uncomment if needed
        // { to: '/admin/permissions', title: 'Permissions', hasBullet: true },
        {to: '/admin/auth-users', title: 'Auth Users', hasBullet: true},
      ],
    },
  ]
  const {allPermissions} = useSelector((state: any) => state.rolesReducer)

  React.useEffect(() => {
    staffRole && dispatch(getPermissions(staffRole._id, true))
  }, [dispatch, pathname])
  return (
    <>
      {/* <div>
    <Link to='/dashboard'>
          <img
            alt='Logo'
            className={`h-18px logo ${isForLight ? 'theme-light-show' : 'theme-dark-show'}`}
            src={toAbsoluteUrl(
              isForLight ? '/media/logos/BattleTrading.svg' : '/media/logos/BattleTradingDark.svg'
            )}
          />
        </Link>
    </div> */}
      {menuItems.map((item) => {
        const isMainMenuPermitted = FindPermission(item, allPermissions)
        if (isMainMenuPermitted) {
          if (item.subItems) {
            return (
              <AsideMenuItemWithSub
                to={item.to}
                title={item.title}
                fontIcon={item.fontIcon}
                icon={item.icon}
                key={item.title}
              >
                {item.subItems.map((subItem) => {
                  const isSubMenuPermitted = FindPermission(subItem, allPermissions)
                  isSubMenuPermitted && dispatch(setPathsToCheck(subItem.to))
                  return (
                    isSubMenuPermitted && (
                      <AsideMenuItem
                        key={subItem.to}
                        to={subItem.to}
                        title={subItem.title}
                        hasBullet={subItem.hasBullet}
                      />
                    )
                  )
                })}
              </AsideMenuItemWithSub>
            )
          } else {
            dispatch(setPathsToCheck(item.to))
            return (
              <AsideMenuItem
                key={item.title}
                to={item.to}
                title={item.title}
                icon={item.icon}
                fontIcon={item.fontIcon}
              />
            )
          }
        }
      })}
    </>
  )
}
